import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { EditorialContentListProps } from '../components/molecules/editorial-content-list';
import { RelatedContent } from '../components/organisms/related-content';
import { TitleCtaProps } from '../components/molecules/title-cta';
import { TagList } from '../components/molecules/tag-list';
import { Image, ImageProps } from '../components/atoms/image';
import { Heading } from '../components/atoms/heading';
import { HeaderSection } from '../components/molecules/header-section';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { CTA, CtaProps } from '../components/molecules/cta';
import { EditorialSection, mapEditorialSectionToComponent } from '../components/organisms/editorial-section';

export type ProgramListSection = {
  title?: TitleCtaProps;
  content?: EditorialContentListProps;
};

export type ProgramProps = {
  header?: {
    title: string;
    tags: string[];
    image?: ImageProps;
  };
  sections: EditorialSection[];
  relatedContent?: ProgramListSection;
  programEndCta?: CtaProps;
};

const ProgramHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
`;

const ProgramBody = styled.div`
  margin: 0 20px;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 0 auto;
  }
`;

export const ProgramTemplate: React.FC<ProgramProps> = props => {
  const theme = useTheme();
  const { isDesktop } = useBreakpoints();

  return (
    <>
      {props.header && (
        <ProgramHeader>
          <HeaderSection>
            <Heading level="h1" text={props.header.title} noMargin />
            <TagList tags={props.header.tags} />
          </HeaderSection>
          <div>
            <Image {...props.header.image} />
          </div>
        </ProgramHeader>
      )}

      <ProgramBody>
        {props.sections?.map((section, index) => {
          const id = `${section.type}-${index}`;
          const nextSection = props.sections[index + 1]?.type;
          return mapEditorialSectionToComponent(section, id, !isDesktop, theme, nextSection);
        })}
      </ProgramBody>
      {props?.programEndCta && (
        <div css={{ display: 'flex', justifyContent: 'center' }}>
          <CTA {...props.programEndCta} />
        </div>
      )}
      {props?.relatedContent?.title && props?.relatedContent?.content && (
        <RelatedContent type="program" {...props.relatedContent} />
      )}
    </>
  );
};
