import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { ProgramTemplate, ProgramProps } from '../templates/program.template';

import SEO from '../components/seo';

import { mapFragmentToEditorialSectionsProps } from '../data-mappers/editorial.mapper';
import {
  mapFragmentToProgramHeaderProps,
  mapFragmentToProgramRelatedContentProps,
} from '../data-mappers/program.mapper';
import { parseLocale } from '../locale';
import { ProgramDataQuery } from '../../graphql-types';
import { safeApply } from '../data-mappers/utils';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { mapFragmentToCtaProps } from '../data-mappers/cta.mapper';
import { LocalizedSlug, useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

type ProgramPageProps = {
  data: ProgramDataQuery;
};

const ProgramPage: React.FC<ProgramPageProps> = ({ data }) => {
  const program = data.cms.program;

  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const programProps: ProgramProps = {
    header: safeApply(value => mapFragmentToProgramHeaderProps(value, locale), program),
    sections: mapFragmentToEditorialSectionsProps(program, locale),
    relatedContent: mapFragmentToProgramRelatedContentProps(program, locale),
    programEndCta: safeApply(value => mapFragmentToCtaProps(value), program?.program_end_cta),
  };

  useBreadcrumbs({
    pageTitle: programProps?.header?.title,
    contentCategory: intl.formatMessage({ id: 'programs' }),
    contentCategoryUrl: '/programs',
  });

  const generateLocalizedSlugs = (data: ProgramDataQuery) => {
    const localizedSlugs: LocalizedSlug[] = [];
    const program = data?.cms?.program;
    program?.localizations?.forEach((localization: any) => {
      if (localization) {
        return localizedSlugs.push({
          locale: localization.locale,
          slug: localization.locale === 'en' ? `programs/${localization.slug}` : `programmes/${localization.slug}`,
        });
      }
    });
    return localizedSlugs;
  };

  useLocalizedSlugs({
    slugs: program?.localizations
      ? generateLocalizedSlugs(data)
      : [
          { locale: 'en', slug: 'programs' },
          { locale: 'fr', slug: 'programmes' },
        ],
  });

  const seoProps = {
    title: programProps?.header?.title ?? '',
    description: program?.metas?.description ?? '',
    lang: locale,
  };

  return (
    <>
      <SEO {...seoProps} />
      <ProgramTemplate {...programProps} />
    </>
  );
};

export default ProgramPage;

export const query = graphql`
  query ProgramData($programId: ID!) {
    cms {
      program(id: $programId) {
        ...Program
        metas {
          id
          description
        }
        image {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        image_mobile {
          ...CommonMediaField
          staticFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        sections {
          __typename
          ...TestimonialsWithImages
          ...AlignedMarkdown
          ...KeyFiguresWithImage
          ...MultipleKpiCards
          ...TextOnBackgroundImage
          ...SimpleImageCard
          ...HomepageTextContentWithMedia
        }
        related_content {
          ...RelatedContentHeader
        }
        programs {
          ...ProgramLink
        }
        program_end_cta {
          ...Link
        }
      }
    }
  }
`;

export const programFragments = graphql`
  fragment Program on cms_Program {
    id
    title
    excerpt
    programLink
    slug
    tags {
      ...Tag
    }
    localizations {
      ...ProgramLocalization
    }
  }

  fragment ProgramLocalization on cms_Program {
    id
    slug
    locale
  }

  fragment ProgramThumbnail on cms_UploadFile {
    id
    name
    caption
    alternativeText
    staticFile {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }

  fragment ProgramWithThumbnail on cms_Program {
    ...Program
    thumbnail {
      ...ProgramThumbnail
    }
  }

  fragment ProgramWithDonationThumbnail on cms_Program {
    ...Program
    donation_page_thumbnail {
      ...ProgramThumbnail
    }
  }
`;
