import { ProgramProps } from '../templates/program.template';
import { RelatedContentHeaderFragment, ProgramDataQuery } from '../../graphql-types';
import { AvailableLocales } from '../locale';
import { mapFragmentToImageProps } from './image.mapper';
import {
  mapFragmentToTagList,
  mapFragmentToTitleCtaProps,
  mapFragmentToEditorialContentThumbmailProps,
} from './homepage.mapper';

import { EditorialContentListProps } from '../components/molecules/editorial-content-list';
import { safeApply, safeMap } from './utils';

export const mapFragmentToProgramHeaderProps = (
  program: NonNullable<ProgramDataQuery['cms']['program']>,
  locale: AvailableLocales
): ProgramProps['header'] => {
  return {
    title: program.title ?? '',
    tags: mapFragmentToTagList(program.tags),
    image: mapFragmentToImageProps(program.image, locale),
  };
};

export function mapFragmentToProgramListProps(
  sections: Array<RelatedContentHeaderFragment | null | undefined>,
  locale: AvailableLocales
): EditorialContentListProps {
  return {
    thumbnails: safeMap(
      fragment => mapFragmentToEditorialContentThumbmailProps(fragment, 'programs', locale),
      sections
    ),
    showContentTags: true,
  };
}

export const mapFragmentToProgramRelatedContentProps = (
  program: ProgramDataQuery['cms']['program'],
  locale: AvailableLocales
): ProgramProps['relatedContent'] => {
  return {
    title: safeApply(value => mapFragmentToTitleCtaProps(value), program?.related_content),
    content: safeApply(value => mapFragmentToProgramListProps(value, locale), program?.programs),
  };
};
